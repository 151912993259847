<script setup lang="ts">
import type { mastodon } from '#types'
import type { MenuItem } from '~/types/menu-item'

const props = defineProps<{
  account: mastodon.v1.Account
  role: 'followers' | 'following'
}>()
const { data } = await useFetch<mastodon.v1.Account[]>(`/api/accounts/${props.account.id}/${props.role}`)
const accountItems = computed<MenuItem<mastodon.v1.Account>[]>(() => {
  return data.value?.map(acc => ({
    label: getDisplayName(acc, { rich: true }),
    to: getAccountRoute(acc),
    meta: acc
  })) ?? []
})
const count = computed(() => {
  return props.account[`${props.role}Count`]
})
</script>

<template>
  <UiPopupMenu :items="accountItems">
    <template #trigger-title>
      <span class="desc">{{ $t(`account-${role}_count`, { count }) }}</span>
    </template>
    <template #item-content="{ item:{meta, label, desc} }">
      <AccountAvatar v-if="meta" :account="meta" />
      <span>
        <span>{{ label }}</span>
        <div v-if="desc" class="desc">{{ desc }}</div>
      </span>
    </template>
  </UiPopupMenu>
</template>
